import React from 'react';
import './Ingredient.css';

function Ingredient(props: { ingredients?: string[]; }) {
    return (
        <div className="ingredients-container">
            <div className="ingredients-title">
                Ingredienti:
            </div>
            {props.ingredients?.map(ingredient => <div key={ingredient} className="prop-name">{ingredient}</div>)}
        </div>
    );
}

export default Ingredient;
