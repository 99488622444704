import React from 'react';
import './Footer.css';
import Arrow from "../Arrow/Arrow";

function fullScreen() {
    if ("requestFullscreen" in document.documentElement) {
        document.documentElement.requestFullscreen();
        window.screen.orientation.lock("portrait");
    }
}

function Footer() {
    return (
        <div className="footer-container">
            <div className="footer-info" onClick={fullScreen}>
                INQUADRA IL CODICE DEL PRODOTTO
            </div>
            <div className="footer-icon">
                <Arrow/>
            </div>
        </div>
    );
}

export default Footer;
