import React from 'react';
import './DetailContainer.css';
import Ingredient from "./Ingredients/Ingredient";
import Allergen from "./Allergens/Allergen";

function DetailContainer(props: { product: Product; url:string }) {
    return (
        <div className="container-detail">
            <div className="name-container">{props.product.name}</div>
            {
                (props.product.ingredients || props.product.allergens?.length) ? (
                    <div className="property-container">
                        <Ingredient ingredients={props.product.arrayIngredients} />
                        <Allergen allergens={props.product.allergens} />
                    </div>
                ) : (<div></div>)
            }
            <div className="image-container">
                <div className="image">
                    <img onError={(event) => (event.target as HTMLImageElement).style.display = 'none'}
                         onLoad={(event) => (event.target as HTMLImageElement).style.display = 'inline-block'}
                         src={props.url+"/images/"+props.product.plu+".png"}
                         alt=""
                         width="400"
                         height="250" />
                </div>
            </div>
        </div>
    );
}

export default DetailContainer;
