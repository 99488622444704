import React from 'react';
import './Detail.css';
import DetailContainer from "./DetailContainer/DetailContainer";
import Header from "../Header/Header";

function Detail(props: { product: Product; url: string }) {
    return (
        <div className="container">
            <div className="header"><Header/></div>
            <div className="detail-container">
                <DetailContainer url={props.url} product={props.product}/>
            </div>
        </div>
    );
}

export default Detail;
