import React, {useEffect} from 'react';
import './Arrow.css';

function Arrow() {
    useEffect(() => {
        const arrow = document.querySelector('.arrow') as HTMLElement;
        arrow.animate([
            {top: '0'},
            {top: '20px'},
            {top: '0'}
        ],{
            duration: 1000,
            iterations: Infinity
        });
    }, [])
    return (
        <div className="icon">
            <div className="arrow"></div>
        </div>
    );
}

export default Arrow;
