import React from 'react';
import './Home.css';
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
function Home() {
    return (
        <div className="container-header">
            <div className="header"><Header /></div>
            <div className="start-animation"></div>
            <div className="footer"><Footer /></div>
        </div>
    );
}

export default Home;
