import React from 'react';
import './Header.css';

function Header() {
    return (
        <div className="header-container">
            <img src="/logo.png" width="350" height="300"  alt=" "/>
        </div>
    );
}

export default Header;
