import React, {useEffect, useState} from 'react';
import './App.css';
import Detail from "./components/Detail/Detail";
import Home from "./components/Home/Home";
let SERVER_URL: string = "https://api.rcarni.it";
let code: string = "";
const MAX_TIMEOUT_FOR_PRODUCT = 60000;
let timeout: NodeJS.Timeout;
let codeTimeout: NodeJS.Timeout;

if (process.env.NODE_ENV === 'production') {
    SERVER_URL = "https://api.rcarni.it";
}

function App() {
    const [currentProduct, setCurrentProduct] = useState<Product>({
        allergens: [],
        arrayIngredients: [],
        id: "",
        ingredients: "",
        name: "",
        plu: ""
    });
    async function detectChange(plu: number) {
        const response = await fetch(`${SERVER_URL}/product?plu=${plu}`, {method: "GET"});
        const product = await response.json() as Product;
        if(product?.plu) {
            setCurrentProduct(product);
        } else {
            setCurrentProduct({allergens: [], arrayIngredients: [], id: "", ingredients: "", name: "Prodotto non riconosciuto", plu: ""})
        }
        console.log(currentProduct)
    }
    document.onkeyup = function (e: KeyboardEvent) {
        code += e.key;
        if(code.length > 10) {
            detectChange(+code?.substring(1,7)).catch(err => console.log(err));
            code = "";
        } else {
            clearTimeout(codeTimeout);
            codeTimeout = setTimeout(() => code="", 500);
        }
        console.log(code)
    }

    useEffect(() => {
        if (currentProduct.name) {
            clearTimeout(timeout)
            timeout = setTimeout(() => setCurrentProduct({
                allergens: [],
                arrayIngredients: [],
                id: "",
                ingredients: "",
                name: "",
                plu: ""
            }), MAX_TIMEOUT_FOR_PRODUCT);
            return;
        }
    },[currentProduct]);

  return (
      <main>
          {currentProduct.name ? <Detail product={currentProduct} url={SERVER_URL} /> : <Home /> }
      </main>
  );
}

export default App;
