import React from 'react';
import './Allergen.css';

function Allergen(props: { allergens?: string[]; }) {
    return (
        <div className="allergens-container">
            <div className="allergens-title">
                Allergeni:
            </div>
            {props?.allergens ? (props.allergens?.map(allergen => <div key={allergen} className="prop-name">{allergen}</div>)) : (<div className="prop-name">Non contiene allergeni</div>)}
        </div>
    );
}

export default Allergen;
